import React from 'react';
import styles from './index.module.less';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { App, Tooltip } from 'antd';
import dayjs from 'dayjs';

const DownloadAndReportContent = ({ imgUrlStr }: { imgUrlStr?: string }) => {
  const { t } = useTranslation('common');
  const { message } = App.useApp();

  const dataList = [
    {
      title: t('Download'),
      icon: '/images/hairstyle/download-icon.webp',
      value: 'Download',
    },
    {
      title: t('Report'),
      icon: '/images/hairstyle/report-icon.webp',
      value: 'Report',
    },
  ];

  const handleDownload = async () => {
    if (imgUrlStr?.length === 0) {
      return;
    }
    // window.open(imgUrlStr, '_blank');
    try {
      const response = await fetch(`/api/download-image?url=${encodeURIComponent(imgUrlStr)}`);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `Hairpaca_${dayjs().format('YYYYMMDDHHmmss')}.png`;

      link.click();
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const items = () => {
    return (
      <div className={styles.listContainer} style={{ color: `var(--app-text-color)` }}>
        {dataList.map((item) => {
          return (
            <div
              className={styles.itemContainer}
              key={item.title}
              onClick={() => {
                if (item?.value === 'Report') {
                  setTimeout(() => {
                    message.success('Report Success');
                  }, 200);
                } else {
                  handleDownload();
                }
              }}
            >
              <Image src={item?.icon} alt={item.title} width={20} height={20} />
              <span>{item.title}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Tooltip title={items} color={'#333333'}>
      <div className={styles.content}>
        <span>{`···`}</span>
      </div>
    </Tooltip>
  );
};

export default DownloadAndReportContent;
