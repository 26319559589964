import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.less';
import { HairLibPublicModel, HairstyleBloggerLabelModel } from '@/types/hairstyle';
import Image from 'next/image';
import { BaseResponse } from '@/types/web.interface';
import { fetchHairstyleLibPublicData } from '@/lib/service';
import { message, Spin } from 'antd';
import { HairstyleMainItemModel } from '@/types/home';
import { imgUrl } from '@/shared/utils';
import DownloadAndReportContent from '@/components/Common/DownloadAndReportContent';
import { useRouter } from 'next/router';
import { useUserProvider } from '@/context/UserProvider';
import UploadImageContent from '@/components/HairChangeContent/UploadImageContent';
import classNames from 'classnames';
import { mainLabelMap } from '@/common/mainLabel';
import { useConfigProvider } from '@/context/ConfigProvider';

const MainContent = ({ data }: { data?: any }) => {
  const router = useRouter();
  const { isLogin, onChangedLoginView, onOpenLoginModal } = useUserProvider();
  const { isMobile } = useConfigProvider();
  const [libId, setLibId] = useState<any>(null);

  const [lebelList, setLebelList] = useState<HairstyleBloggerLabelModel[]>(data?.lebelList ?? []);
  const [dataList, setDataList] = useState<HairLibPublicModel[]>(data?.dataList ?? []);

  const [hairstyleRes, setHairstyleRes] = useState<BaseResponse<HairLibPublicModel[]>>();
  const [mainLoading, setMainLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(1);

  const showMoreList = useMemo(() => {
    return dataList?.length > currentIndex * 10;
  }, [dataList, currentIndex]);

  const getDataList = useMemo(() => {
    return dataList?.slice(0, currentIndex * 10);
  }, [dataList, currentIndex]);

  const genderList = useMemo(() => {
    let tempVal = data?.pathParam?.gender;
    if (tempVal !== 'men' && tempVal !== 'women') {
      tempVal = 'all';
    }
    return [
      {
        lebal: 'All',
        value: 'all',
        select: tempVal === 'all',
      },
      {
        lebal: 'Women',
        value: 'women',
        select: tempVal === 'women',
      },
      {
        lebal: 'Men',
        value: 'men',
        select: tempVal === 'men',
      },
    ];
  }, [data?.pathParam]);

  useEffect(() => {
    setLebelList(data?.lebelList ?? []);
    setDataList(data?.dataList ?? []);
  }, [data]);

  function getRandomColor(): string {
    // 生成随机的明亮颜色，避免深色
    const getRandomBrightValue = () => {
      // 限制在128-255之间以确保颜色明亮
      return Math.floor(Math.random() * 128) + 128;
    };

    // 生成RGB三个通道的值，确保都在明亮范围内
    const red = getRandomBrightValue();
    const green = getRandomBrightValue();
    const blue = getRandomBrightValue();

    // 转换为16进制颜色代码
    return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue
      .toString(16)
      .padStart(2, '0')}`;
  }

  // 判断pc label长度
  useEffect(() => {
    if (!isMobile) {
      if (data?.lebelList?.length < 10) {
        setShowMore(true);
      }
    } else {
      setShowMore(false);
    }
  }, [isMobile, data?.lebelList]);

  const fetchHairstyleData = async (
    page_no: number,
    initState: boolean = false,
    attempt = 1,
    maxAttempts = 3,
  ) => {
    try {
      const res = await fetchHairstyleLibPublicData(
        {
          page_no: page_no,
          page_size: 30,
          gender: data?.pathParam?.gender,
          label: data?.pathParam?.label,
        },
        mainLabelMap.get(data?.pathParam?.pathId as string),
      );
      if (res?.code === 0) {
        const newData = res?.data ?? [];
        // 对返回结果去重，根据 lib_id
        const currentData = hairstyleRes?.data ?? [];
        if (currentData.length === 0 || initState) {
          setHairstyleRes({ ...res });
        } else {
          const uniqueData = Array.from(
            new Set([...currentData, ...newData].map((item) => item.lib_id)),
          ).map((id) => {
            return [...currentData, ...newData].find((item) => item.lib_id === id);
          });
          const isNewData = uniqueData.length > currentData.length;
          setHairstyleRes({ ...res, data: uniqueData });
          // 如果没有新的数据，并且尝试次数未超过最大限制，则再请求一次
          if (!isNewData && attempt < maxAttempts) {
            await fetchHairstyleData(page_no, false, attempt + 1, maxAttempts);
          }
          if (attempt === maxAttempts) {
            // setNoData(true);
          }
        }
        setMainLoading(false);
      } else {
        message.error(res?.message);
        setMainLoading(false);
      }
    } catch (e) {
      setMainLoading(false);
      message.error('error');
    }
  };

  const getItem = (item: HairstyleMainItemModel) => {
    return (
      <div key={item?.lib_id} className={styles.hairstyleCard}>
        <div className={styles.imageContainer}>
          <img src={imgUrl(item?.image_url)} alt={item?.image_alt} loading="lazy" />
          <div className={styles.editBadge}>
            <Image
              src={imgUrl(item?.blogger_avatar)}
              alt={''}
              width={24}
              height={24}
              style={{ width: '24px', height: '24px', borderRadius: '12px' }}
            />
            <span>{item?.blogger_name}</span>
          </div>
          <div className={styles.topRightContainer}>
            <DownloadAndReportContent imgUrlStr={item?.image_url} />
          </div>

          <div className={styles.tryOnContainer}>
            <span
              onClick={() => {
                if (isLogin) {
                  setLibId(item?.lib_id);
                } else {
                  onChangedLoginView(true);
                  onOpenLoginModal(true);
                }
              }}
            >
              {'Try on'}
            </span>
          </div>
        </div>
        <div className={styles.tags}>
          {item?.labels?.map((tag, tagIndex) => {
            const tempColor = getRandomColor();
            return (
              <span
                key={tagIndex}
                className={styles.tag}
                style={{ color: tempColor, border: `1px solid ${tempColor}` }}
                onClick={() => {
                  router.push(`/hairstyle?label=${tag}`);
                }}
              >
                {tag}
              </span>
            );
          })}
        </div>
      </div>
    );
  };

  const onChangePath = (gender: string, label?: string) => {
    if (gender === 'all') {
      if (label?.length > 0 && label?.toLocaleLowerCase() !== 'all') {
        router.replace(`/${data?.pathParam?.pathId}/${label?.toLocaleLowerCase()}`);
      } else {
        router.replace(`/${data?.pathParam?.pathId}`);
      }
    } else {
      if (label?.length > 0 && label?.toLocaleLowerCase() !== 'all') {
        router.replace(
          `/${
            data?.pathParam?.pathId
          }/${gender?.toLocaleLowerCase()}-${label?.toLocaleLowerCase()}`,
        );
      } else {
        router.replace(`/${data?.pathParam?.pathId}/${gender?.toLocaleLowerCase()}`);
      }
    }
  };

  return (
    <>
      <Spin spinning={mainLoading}>
        <div className={styles.content}>
          <div className={styles.topBtns}>
            {genderList?.map((item, index) => {
              const styleStr = item?.select ? `${styles.active1}` : `${styles.active2}`;
              return (
                <span
                  key={item.value}
                  className={styleStr}
                  onClick={() => {
                    onChangePath(item?.value, data?.pathParam?.label);
                  }}
                >
                  {item?.lebal}
                </span>
              );
            })}
          </div>

          <div className={styles.labelGroundContainer}>
            <div className={classNames([styles.imgLabelsList, showMore && styles.moreLabelsList])}>
              {lebelList?.map((item) => {
                return (
                  <div
                    key={item.label}
                    className={classNames([
                      styles.imgLabelContainer,
                      data?.pathParam?.label === item?.label?.toLocaleLowerCase() &&
                        styles.imgLabelActive,
                    ])}
                    onClick={() => {
                      onChangePath(data?.pathParam?.gender, item?.label);
                    }}
                  >
                    <Image
                      className={styles.imgContainer}
                      src={item?.image}
                      width={50}
                      height={50}
                      alt={item?.label}
                    />
                    <span>{item?.label}</span>
                  </div>
                );
              })}
            </div>

            {!showMore && (
              <div className={styles.moreContainer} onClick={() => setShowMore(true)}>
                <span>{`More`}</span>
                <Image
                  src="/images/hairstyle/arrow_down_icon_1.webp"
                  width={16}
                  height={10}
                  alt="arrow down"
                />
              </div>
            )}
          </div>

          <div className={styles.hairstyleGrid}>
            {getDataList?.map((item, index) => getItem(item))}
          </div>

          {showMoreList && (
            <div className={styles.moreButtonContainer}>
              <button
                className={styles.moreButton}
                onClick={() => {
                  setCurrentIndex(currentIndex + 1);
                }}
              >
                more <span className={styles.arrowDown}>▼</span>
              </button>
            </div>
          )}
        </div>
      </Spin>

      <UploadImageContent openId={libId} handleClose={() => setLibId(null)} />
    </>
  );
};

export default MainContent;
