import { upperFirst } from 'lodash';

export const mainLabelMap = new Map<string, string>([
  ['virtual-hair-color-try-on', 'Colors'],
  ['hair-types', 'Hair Types'],
  ['best-hairstyles-for-face-shape', 'Face Shapes'],
  ['trending-hairstyles', 'Trending'],
]);

export const mainLabelReverseMap = new Map<string, string>([
  ['Colors', 'virtual-hair-color-try-on'],
  ['Hair Types', 'hair-types'],
  ['Face Shapes', 'best-hairstyles-for-face-shape'],
  ['Trending', 'trending-hairstyles'],
]);

/**
 * 获取路由标题
 * @param label 标签
 * @param gender 性别
 */
export const getRouteTitleMap = (routePath: string, label: string, gender: string) => {
  // 检查是否为有效选择（不为空且不为'all'）
  const hasLabel = label && label !== 'all';
  const hasGender = gender && gender !== 'all';

  // 基础标题映射
  const baseTitles = new Map<string, string>([
    ['trending-hairstyles', 'Trending Hairstyles in 2025'],
    ['virtual-hair-color-try-on', 'Virtual Hair Color Try-On'],
    ['hair-types', 'Best Hairstyles For Each Hair Type'],
    ['best-hairstyles-for-face-shape', 'The Best Hairstyles for Any Face Shape'],
  ]);

  // 将标签和性别首字母大写
  const formattedLabel = hasLabel ? upperFirst(label) : '';
  const formattedGender = hasGender ? upperFirst(gender) : '';

  // best-hairstyles-for-face-shape 路径的特殊处理
  if (routePath === 'best-hairstyles-for-face-shape') {
    if (formattedLabel && formattedGender) {
      return `The Best Hairstyles for ${formattedGender}'s ${formattedLabel} Faces`;
    }
    if (formattedLabel) {
      return `The Best Hairstyles for ${formattedLabel} Faces`;
    }
    if (formattedGender) {
      return `The Best Hairstyles for ${formattedGender} Faces`;
    }
    return baseTitles.get(routePath);
  }
  if (routePath === 'hair-types') {
    if (formattedLabel && formattedGender) {
      return `The Best Hairstyles for ${formattedGender} with ${formattedLabel} Hair`;
    }
    if (formattedLabel) {
      return `The Best Hairstyles for ${formattedLabel} Hair`;
    }
    if (formattedGender) {
      return `The Best Hairstyles for ${formattedGender}`;
    }
    return baseTitles.get(routePath);
  }
  if (formattedLabel && formattedGender) {
    // 其他路径的处理
    if (routePath === 'trending-hairstyles') {
      return `${formattedGender} ${formattedLabel} Trending Hairstyles in 2025`;
    }
    return `${formattedGender} ${formattedLabel} ${baseTitles.get(routePath)}`;
  }

  if (formattedLabel) {
    return `${formattedLabel} ${baseTitles.get(routePath)}`;
  }

  if (formattedGender) {
    return `${formattedGender} ${baseTitles.get(routePath)}`;
  }

  return baseTitles.get(routePath);
};

export const getPageTitleMap = (routePath: string, labelTag: string, genderTag: string) => {
  const hasLabel = labelTag && labelTag !== 'all';
  const hasGender = genderTag && genderTag !== 'all';
  if (routePath === 'best-hairstyles-for-face-shape') {
    if (!hasLabel && !hasGender) {
      return 'Any Face Shape';
    }
    if (hasLabel && !hasGender) {
      return `${upperFirst(labelTag)} Faces`;
    }
    if (!hasLabel && hasGender) {
      return `${upperFirst(genderTag)} Faces`;
    }
    return `${upperFirst(genderTag)}'s ${upperFirst(labelTag)} Faces`;
  }
  if (routePath === 'hair-types') {
    if (hasLabel && hasGender) {
      return `${upperFirst(genderTag)} With ${upperFirst(labelTag)} Hair`;
    }
    if (hasLabel) {
      return `${upperFirst(labelTag)} Hair`;
    }
    if (hasGender) {
      return `${upperFirst(genderTag)}`;
    }
    return 'Each Hair Type';
  }
  if (!hasLabel && !hasGender) {
    return '';
  }
  if (!hasGender && hasLabel) {
    return `${upperFirst(labelTag)}`;
  } else if (hasGender) {
    let result = `${upperFirst(genderTag)}` + `'s`;
    if (hasLabel) {
      result += ` ${upperFirst(labelTag)}`;
    }
    return result;
  }
  return '';
};
